import { MDBBtn } from "mdb-react-ui-kit";

interface HeaderProps{
    backgroundImg : string;
    h1Text : string;
    h4Text : string;
}

function HeaderComponent({backgroundImg, h1Text, h4Text} : HeaderProps){
    return(
        <>
        <header>
        <div className='text-center bg-image' style={{ 
            backgroundImage: `url(${backgroundImg})`, 
            height: '80vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat' 
        }}>
        <div className='mask' style={{ 
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            height: "100%" 
            }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
                <h1 className='mb-3'>{h1Text}</h1>
                <h4 className='mb-3'>{h4Text}</h4>
                <MDBBtn outline rounded color='light'>
                    CTA
                </MDBBtn>
            </div>
          </div>
        </div>
      </div>
        </header>
        </>
    );
}

export default HeaderComponent;