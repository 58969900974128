import { MDBCollapse, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler } from 'mdb-react-ui-kit';
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';

interface NavProps{
  logoSrc: string;
  linkItems: string[];
}

function NavComponent({ logoSrc, linkItems } : NavProps) {
  const [openNav, setOpenNav] = useState(false);
  
  return (
    <>
      <MDBNavbar className='px-4' expand='lg' dark bgColor='dark'>
        <MDBNavbarBrand href="/Home">
          <img
          alt="Logo image"
          src={logoSrc}
          width="50"
          height="50"
          className="d-inline-block align-top"
        />
        </MDBNavbarBrand>
        <Navbar.Toggle className='dark' aria-controls="basic-navbar-nav" onClick={() => setOpenNav(!openNav)}/>
        <MDBCollapse navbar open={openNav}>
          <MDBNavbarNav >
            {
              linkItems.map( (item) => (
                <MDBNavbarItem key={`${item}-item`} className='text-center'>
                  <MDBNavbarLink key={`${item}-link`} aria-current="page" href={`/${item}`}>
                    {item}
                  </MDBNavbarLink>
                </MDBNavbarItem>
              ))
            }
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </>
  );
}

export default NavComponent;