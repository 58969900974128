import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn, MDBRipple } from 'mdb-react-ui-kit';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

export interface CardProps{
    imgSrc : string;
    title : string;
    content : string;
}

interface CardContainerProps{
    listCard : CardProps[];
}

function CardComponent({ imgSrc, title, content }: CardProps) {
  const [isHovered, setIsHovered] = useState(false);

  const toggleOverlay = () => {
    setIsHovered(!isHovered);
  };

  return (
    <MDBCard className='mt-5'>
      <div style={{ position: 'relative' }} onMouseEnter={toggleOverlay} onMouseLeave={toggleOverlay}>
        <MDBCardImage src={imgSrc} position='top' alt='...' />
        {isHovered && (
          <div className="overlay">
          </div>
        )}
      </div>
      <MDBCardBody>
        <MDBCardTitle>{title}</MDBCardTitle>
        <MDBCardText>
          {content}
        </MDBCardText>
        <MDBBtn href='#'>Button</MDBBtn>
      </MDBCardBody>
    </MDBCard>
  );
}

export function CardContainerComponent({ listCard }: CardContainerProps) {
    return (
      <Container>
        <Row xs={1} md={2} lg={3}>
          {listCard.map((card, index) => (
            <Col key={index}>
              <CardComponent imgSrc={card.imgSrc} title={card.title} content={card.content} />
            </Col>
          ))}
        </Row>
      </Container>
    );
}