import HeaderComponent from "../components/HeaderComponent";
import NavComponent from "../components/NavComponent";
import { CardProps, CardContainerComponent } from "../components/CardComponent";
import FooterComponent from "../components/FooterComponent";


function Home(){
    const menuItems = ["Home", "Photos", "Videos", "Travels", "Maps"]
    const cardItems : CardProps[] = [
        { imgSrc: 'https://source.unsplash.com/random/800x600', title: 'Card 1', content: 'This is the content for card 1.' },
        { imgSrc: 'https://source.unsplash.com/random/800x601', title: 'Card 2', content: 'This is the content for card 2.' },
        { imgSrc: 'https://source.unsplash.com/random/800x602', title: 'Card 3', content: 'This is the content for card 3.' },
        { imgSrc: 'https://source.unsplash.com/random/800x603', title: 'Card 4', content: 'This is the content for card 4.' },
        { imgSrc: 'https://source.unsplash.com/random/800x604', title: 'Card 5', content: 'This is the content for card 5.' },
        { imgSrc: 'https://source.unsplash.com/random/800x605', title: 'Card 6', content: 'This is the content for card 6.' }
      ];

    return (
        <>
            <NavComponent logoSrc="Logo-web.png" linkItems={menuItems}/>
            <HeaderComponent backgroundImg="backgroundHeader.jpeg" h1Text="Viaggi on the road: libertà denza confini" h4Text="Avventure in Van con panorami mozzafiato"></HeaderComponent>
            <CardContainerComponent listCard={cardItems} />
            <FooterComponent />
        </>
    );
}

export default Home;